import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import Navcomponent from "./navbar";
import { motion as m } from "framer-motion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebookF,FaTwitter,FaLinkedinIn } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import Phone from "./phone";
import Contact from "./contact";
import { HashLink as Link } from "react-router-hash-link";
import { gsap, Power2 } from "gsap";
import SplitType from "split-type";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { BsArrowRightCircleFill,BsArrowLeftCircleFill  } from "react-icons/bs";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { data } from "jquery";

const ProductDetail = () => {
  const [ids, setIds] = useState();
  const params = useParams();
  const id = params.id;
  console.log(params)
  const form = useRef();
  const [formState, setFormState] = useState({});
  const state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    console.log("pathcheck", id);
    setIds(id);
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });

      // let tl1 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: revealtext,
      //     toggleActions: "restart none none reset",
      //   },
      // });

      // tl1.to(headingreveal, {
      //   y: 0,
      //   stagger: 0.05,
      //   delay: 0.3,
      //   duration: 0.1,
      // });
    });
    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4ikopok",
        "template_ijgmu1o",
        form.current,
        "IyA-bzBzbkBDltsgB"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you! We've received your message. Tean will contact you shortly.",
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        },
        (error) => {
          toast.error("Incorrect Email", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
  };

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  const MyVerticallyCenteredModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            LEAVE US A NOTE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={form} onSubmit={sendEmail} className="emailform">
            {/* <form ref={form} className="emailform"> */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contactform"
                    id="exampleInputFname"
                    placeholder="First Name *"
                    name="fname"
                    onChange={changeHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contactform"
                    id="exampleInputFname"
                    placeholder="Last Name *"
                    name="lname"
                    onChange={changeHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control contactform"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email Address *"
                    name="email"
                    onChange={changeHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="phone"
                    className="form-control contactform"
                    id="exampleInputPhone"
                    aria-describedby=""
                    placeholder="Phone Number *"
                    name="phone"
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <textarea
                    className="form-control contactform"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Your Message"
                    name="message"
                    onChange={changeHandler}
                    required
                  ></textarea>
                </div>
              </div>
            </div>

            <button type="submit" className="submitemail">
              Submit
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} className="quotebtn">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const Detail = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const pageUrl = window.location.href;
    const imgs = [
      {
        id: 0,
        color: "white",
        category: "outdoorcanopy",
        url: "/assets/images/canopy1.png",
      },
      {
        id: 1,
        color: "white",
        category: "outdoorcanopy",
        url: "/assets/images/canopy2.png",
      },
      {
        id: 2,
        color: "white",
        category: "outdoorcanopy",
        url: "/assets/images/somepoints.png",
      },
      {
        id: 3,
        color: "black",
        category: "outdoorcanopy",
        url: "/assets/images/Product IMG2.png",
      },
      {
        id: 4,
        color: "black",
        category: "outdoorcanopy",
        url: "/assets/images/shoerack3.png",
      },
      {
        id: 5,
        color: "black",
        category: "outdoorcanopy",
        url: "/assets/images/shoerac.png",
      },
      {
        id: 6,
        color: "silver",
        category: "outdoorcanopy",
        url: "/assets/images/shoerac.png",
      },
      {
        id: 7,
        color: "silver",
        category: "outdoorcanopy",
        url: "/assets/images/shoerac.png",
      },
      {
        id: 8,
        color: "silver",
        category: "outdoorcanopy",
        url: "/assets/images/shoerac.png",
      },
      {
        id: 9,
        color: "black",
        category: "locker",
        url: "/assets/images/lockerb1.jpg",
      },
      {
        id: 10,
        color: "black",
        category: "locker",
        url: "/assets/images/lockerb2.jpg",
      },
      {
        id: 11,
        color: "black",
        category: "locker",
        url: "/assets/images/lockerb3.jpg",
      },
      {
        id: 12,
        color: "black",
        category: "locker",
        url: "/assets/images/lockerb4.jpg",
      },
      {
        id: 13,
        color: "white",
        category: "locker",
        url: "/assets/images/lockerw1.jpg",
      },
      {
        id: 14,
        color: "white",
        category: "locker",
        url: "/assets/images/lockerw2.jpg",
      },
      {
        id: 15,
        color: "white",
        category: "locker",
        url: "/assets/images/lockerw3.jpg",
      },
      {
        id: 16,
        color: "white",
        category: "locker",
        url: "/assets/images/lockerw4.jpg",
      },
      {
        id: 17,
        color: "silver",
        category: "locker",
        url: "/assets/images/lockers1.jpg",
      },
      {
        id: 18,
        color: "silver",
        category: "locker",
        url: "/assets/images/lockers2.jpg",
      },
      {
        id: 19,
        color: "silver",
        category: "locker",
        url: "/assets/images/lockker2.png",
      },
      {
        id: 20,
        color: "silver",
        category: "locker",
        url: "/assets/images/locker.png",
      },
      {
        id: 21,
        color: "silver",
        category: "lockercover",
        url: "/assets/images/lockerCover.jpg",
      },
      {
        id: 22,
        color: "white",
        category: "shoerackcover",
        url: "/assets/images/showrackCover.jpg",
      },
      {
        id: 23,
        color: "white",
        category: "shoerack",
        url: "/assets/images/wshoerack1.jpg",
      },
      {
        id: 24,
        color: "white",
        category: "shoerack",
        url: "/assets/images/wshoerack2.jpg",
      },
      {
        id: 25,
        color: "white",
        category: "shoerack",
        url: "/assets/images/wshoerack3.jpg",
      },
      {
        id: 26,
        color: "white",
        category: "shoerack",
        url: "/assets/images/wshoerack4.jpg",
      },
      {
        id: 27,
        color: "black",
        category: "shoerack",
        url: "/assets/images/bshoerack1.jpg",
      },
      {
        id: 28,
        color: "black",
        category: "shoerack",
        url: "/assets/images/bshoerack2.jpg",
      },
      {
        id: 29,
        color: "black",
        category: "shoerack",
        url: "/assets/images/bshoerack3.jpg",
      },
      {
        id: 30,
        color: "black",
        category: "shoerack",
        url: "/assets/images/bshoerack4.jpg",
      },
      {
        id: 31,
        color: "silver",
        category: "shoerack",
        url: "/assets/images/sshoerack1.jpg",
      },
      {
        id: 32,
        color: "silver",
        category: "shoerack",
        url: "/assets/images/sshoerack2.jpg",
      },
      {
        id: 33,
        color: "silver",
        category: "shoerack",
        url: "/assets/images/sshoerack3.jpg",
      },
      {
        id: 34,
        color: "silver",
        category: "shoerack",
        url: "/assets/images/sshoerack4.jpg",
      },
      {
        id: 35,
        color: "white",
        category: "wardrobe",
        url: "/assets/images/wardrobecover.jpg",
      },
      {
        id: 36,
        color: "white",
        category: "wardrobe",
        url: "/assets/images/wwardrobe1.jpg",
      },
      {
        id: 37,
        color: "white",
        category: "wardrobe",
        url: "/assets/images/wwardrobe2.jpg",
      },
      {
        id: 38,
        color: "white",
        category: "wardrobe",
        url: "/assets/images/wwardrobe3.jpg",
      },
      {
        id: 39,
        color: "white",
        category: "wardrobe",
        url: "/assets/images/wwardrobe4.jpg",
      },
      {
        id: 40,
        color: "black",
        category: "wardrobe",
        url: "/assets/images/bwardrobe1.jpg",
      },
      {
        id: 41,
        color: "black",
        category: "wardrobe",
        url: "/assets/images/bwardrobe2.jpg",
      },
      {
        id: 42,
        color: "black",
        category: "wardrobe",
        url: "/assets/images/bwardrobe3.jpg",
      },
      {
        id: 43,
        color: "black",
        category: "wardrobe",
        url: "/assets/images/bwardrobe4.jpg",
      },
      {
        id: 44,
        color: "silver",
        category: "wardrobe",
        url: "/assets/images/swardrobe1.jpg",
      },
      {
        id: 45,
        color: "silver",
        category: "wardrobe",
        url: "/assets/images/swardrobe2.jpg",
      },
      {
        id: 46,
        color: "silver",
        category: "wardrobe",
        url: "/assets/images/swardrobe3.jpg",
      },
      {
        id: 47,
        color: "silver",
        category: "wardrobe",
        url: "/assets/images/swardrobe4.jpg",
      },
      {
        id: 48,
        color: "white",
        category: "shoerack3cover",
        url: "/assets/images/shoerack3Cover.jpg",
      },
      {
        id: 49,
        color: "white",
        category: "shoerack3",
        url: "/assets/images/wshoerack31.jpg",
      },
      {
        id: 50,
        color: "white",
        category: "shoerack3",
        url: "/assets/images/wshoerack32.jpg",
      },
      {
        id: 51,
        color: "white",
        category: "shoerack3",
        url: "/assets/images/wshoerack33.jpg",
      },
      {
        id: 52,
        color: "white",
        category: "shoerack3",
        url: "/assets/images/wshoerack34.jpg",
      },
      {
        id: 53,
        color: "black",
        category: "shoerack3",
        url: "/assets/images/bshoerack31.jpg",
      },
      {
        id: 54,
        color: "black",
        category: "shoerack3",
        url: "/assets/images/bshoerack32.jpg",
      },
      {
        id: 55,
        color: "black",
        category: "shoerack3",
        url: "/assets/images/bshoerack33.jpg",
      },
      {
        id: 56,
        color: "black",
        category: "shoerack3",
        url: "/assets/images/bshoerack34.jpg",
      },
      {
        id: 57,
        color: "silver",
        category: "shoerack3",
        url: "/assets/images/sshoerack31.jpg",
      },
      {
        id: 58,
        color: "silver",
        category: "shoerack3",
        url: "/assets/images/sshoerack32.jpg",
      },
      {
        id: 59,
        color: "silver",
        category: "shoerack3",
        url: "/assets/images/sshoerack33.jpg",
      },
      {
        id: 60,
        color: "silver",
        category: "shoerack3",
        url: "/assets/images/sshoerack34.jpg",
      },
    ];
    const [imgData, setImgData] = useState(imgs[0]);
    useEffect(() => {
      if (ids === "1") {
        setImgData(imgs[0])
      }
      if (ids === "2") {
        setImgData(imgs[21])
      }
      if (ids === "3") {
        setImgData(imgs[22])
      }
      if (ids === "4") {
        setImgData(imgs[22])
      }
      if (ids === "5") {
        setImgData(imgs[48])
      }
      if (ids === "6") {
        setImgData(imgs[35])
      }
    },[])
    const [filterData, setFilterData] = useState(imgs);
    const [val, setVal] = useState(0);
    const [slide, setSlide] = useState(0);
    const ref = useRef(null);

    const setImages = (index) => {
      setVal(index);
      const imgSlider = imgs[index];
      setImgData(imgSlider);
    };

    const setColor = (color, category) => {
      if (color === "black" && category === "outdoorcanopy") {
        const filtered = imgs.filter(
          (img) => img.color === "black" && img.category === "outdoorcanopy"
        );
        setFilterData(filtered);
      } else if (color === "white" && category === "outdoorcanopy") {
        const filtered = imgs.filter(
          (img) => img.color === "white" && img.category === "outdoorcanopy"
        );
        setFilterData(filtered);
      } else if (color === "silver" && category === "outdoorcanopy") {
        const filtered = imgs.filter(
          (img) => img.color === "silver" && img.category === "outdoorcanopy"
        );
        setFilterData(filtered);
      } else if (color === "black" && category === "locker") {
        const filtered = imgs.filter(
          (img) => img.color === "black" && img.category === "locker"
        );
        setFilterData(filtered);
      } else if (color === "white" && category === "locker") {
        const filtered = imgs.filter(
          (img) => img.color === "white" && img.category === "locker"
        );
        setFilterData(filtered);
      } else if (color === "silver" && category === "locker") {
        const filtered = imgs.filter(
          (img) => img.color === "silver" && img.category === "locker"
        );
        setFilterData(filtered);
      } else if (color === "white" && category === "shoerack") {
        const filtered = imgs.filter(
          (img) => img.color === "white" && img.category === "shoerack"
        );
        setFilterData(filtered);
      }
      else if (color === "black" && category === "shoerack") {
        const filtered = imgs.filter(
          (img) => img.color === "black" && img.category === "shoerack"
        );
        setFilterData(filtered);
      }
      else if (color === "silver" && category === "shoerack") {
        const filtered = imgs.filter(
          (img) => img.color === "silver" && img.category === "shoerack"
        );
        setFilterData(filtered);
      }
      else if (color === "white" && category === "wardrobe") {
        const filtered = imgs.filter(
          (img) => img.color === "white" && img.category === "wardrobe"
        );
        setFilterData(filtered);
      }
      else if (color === "black" && category === "wardrobe") {
        const filtered = imgs.filter(
          (img) => img.color === "black" && img.category === "wardrobe"
        );
        setFilterData(filtered);
      }
      else if (color === "silver" && category === "wardrobe") {
        const filtered = imgs.filter(
          (img) => img.color === "silver" && img.category === "wardrobe"
        );
        setFilterData(filtered);
      }
      else if (color === "white" && category === "shoerack3") {
        const filtered = imgs.filter(
          (img) => img.color === "white" && img.category === "shoerack3"
        );
        setFilterData(filtered);
      }
      else if (color === "black" && category === "shoerack3") {
        const filtered = imgs.filter(
          (img) => img.color === "black" && img.category === "shoerack3"
        );
        setFilterData(filtered);
      }
      else if (color === "silver" && category === "shoerack3") {
        const filtered = imgs.filter(
          (img) => img.color === "silver" && img.category === "shoerack3"
        );
        setFilterData(filtered);
      }
      else {
      }
    };
    
    const nextSlide = () => {
      console.log(ref.current)
        ref.current.scrollLeft += 65;
    }
    const prevSlide = () => {
      console.log(ref.current)
      ref.current.scrollLeft -= 65;

    }

    if (ids === "1") {
  
      return (
        <>
          <div className="container">
            <div className="row pb100 borderbottom item-center">


              {/* <div className="col-md-6">
               <img src={imgData.url} height="300" className="w-100 sliderparentimg" alt="" />
                <div className="flex_row">
                  <div className="row">
                    {filterData.map((data, i) =>
                      data.category === "outdoorcanopy" ? (
                        <div className="col-md-3 col-xs-4">
                          <div className="thumbnail" key={i}>
                            <img
                              className={data.id === imgData.id ? "clicked" : ""}
                              src={data.url}
                              onClick={() => setImages(data.id)}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 sliderparent"> 
                  <img src={imgData.url} className="w-100 sliderparentimg" alt="" />
                    <div className="carosel" ref={ref}>
                      {filterData.map((data, i) =>
                        data.category === "outdoorcanopy" ? (
                              <img key={i} 
                                className={ (slide === i ? "slide" : "slide") + " " + (data.id === imgData.id ? "clicked" : "")}
                                src={data.url}
                                onClick={() => setImages(data.id)}
                              />
                        ) : (
                          ""
                        )
                      )}
                      
                      {/* <span className="indicators">
                        {filterData.map((_, idx) => {
                            return <button key={idx} onClick={null} className="indicator"></button>
                          })}
                      </span> */}
                      </div>
                      <div className="arrow-btns">
                      <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
                      <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
                      </div>
                </div>
              <div className="col-md-6">
              <div className="product-material-mobile">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant=""
                      onClick={() => setColor("white", "outdoorcanopy")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/whitedot.svg"
                        }
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button
                      variant=""
                      onClick={() => setColor("black", "outdoorcanopy")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button
                      variant=""
                      onClick={() => setColor("silver", "outdoorcanopy")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>
                <h2 className="grotesklight">OUT DOOR CANOPY</h2>
                <div className="product-description">
                  <p className="grotesklight pbottom20 ptop20">
                    The Italia 3D peel is a top-of-the-line professional
                    perforated shovel, characterised by its unique
                    three-dimensional design, with elevations on the peel plate,
                    which reduce friction and increase the sliding of the pizza
                    on the plate.
                  </p>
                </div>
                <div className="product-material mb50">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant=""
                      onClick={() => setColor("white", "outdoorcanopy")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/whitedot.svg"
                        }
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button
                      variant=""
                      onClick={() => setColor("black", "outdoorcanopy")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button
                      variant=""
                      onClick={() => setColor("silver", "outdoorcanopy")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>

                <Button
                  className="white quotebtn btn quotemobilebtn"
                  onClick={() => setModalShow(true)}
                >
                  GET YOUR QUOTE
                </Button>
                {/* <a href="https://api.whatsapp.com/send?phone=03331560504" target='_blank'><IoLogoWhatsapp className="whatsappicon"/></a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=03331560504"
                  target="_blank"
                  className="grotesklight black whatsappbtn"
                >
                  <IoLogoWhatsapp /> Whatsapp
                </a>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                <div className="social ptop50">
                  <h6 className="grotesklight inline">Share on</h6>
                  <ul className="socialmedia sharebtns">
                    <li>
                      <a
                        href={`https://www.facebook.com/share.php?u=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.twitter.com/share?&url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pb100 borderbottom">
              <h2 className="grotesklight ptop100">PRODUCT SPECIFICATIONS</h2>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "2") {
      return (
        <>
          <div className="container">
            <div className="row pb100 borderbottom item-center">
                <div className="col-md-6 sliderparent">
                  <img src={imgData.url} className="w-100 sliderparentimg" alt="" />   
                    <div className="carosel" ref={ref}>
                      {filterData.map((data, i) =>
                        data.category === "locker" ? (
                              <img key={i} 
                                className={ (slide === i ? "slide" : "slide") + " " + (data.id === imgData.id ? "clicked" : "")}
                                src={data.url}
                                onClick={() => setImages(data.id)}
                              />
                        ) : (
                          ""
                        )
                      )}
                      
                      {/* <span className="indicators">
                        {filterData.map((_, idx) => {
                            return <button key={idx} onClick={null} className="indicator"></button>
                          })}
                      </span> */}
                      </div>
                      <div className="arrow-btns">
                      <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
                      <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
                      </div>
                </div>
              <div className="col-md-6">
              <div className="product-material-mobile">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant=""
                      onClick={() => setColor("white", "locker")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/whitedot.svg"
                        }
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button
                      variant=""
                      onClick={() => setColor("black", "locker")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>

                    <Button
                      variant=""
                      onClick={() => setColor("silver", "locker")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>
                <h2 className="grotesklight">LOCKER</h2>
                <div className="product-description">
                  <p className="grotesklight pbottom20 ptop20">
                    The Italia 3D peel is a top-of-the-line professional
                    perforated shovel, characterised by its unique
                    three-dimensional design, with elevations on the peel plate,
                    which reduce friction and increase the sliding of the pizza
                    on the plate.
                  </p>
                </div>
                <div className="product-material mb50">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant=""
                      onClick={() => setColor("white", "locker")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/whitedot.svg"
                        }
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button
                      variant=""
                      onClick={() => setColor("black", "locker")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>

                    <Button
                      variant=""
                      onClick={() => setColor("silver", "locker")}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>

                <Button
                  className="white quotebtn btn quotemobilebtn"
                  onClick={() => setModalShow(true)}
                >
                  GET YOUR QUOTE
                </Button>
                {/* <a href="https://api.whatsapp.com/send?phone=03331560504" target='_blank'><IoLogoWhatsapp className="whatsappicon"/></a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=03331560504"
                  target="_blank"
                  className="grotesklight black whatsappbtn"
                >
                  <IoLogoWhatsapp /> Whatsapp
                </a>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                 <div className="social ptop50">
                  <h6 className="grotesklight inline">Share on</h6>
                  <ul className="socialmedia sharebtns">
                    <li>
                      <a
                        href={`https://www.facebook.com/share.php?u=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.twitter.com/share?&url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pb100 borderbottom">
              <h2 className="grotesklight ptop100">PRODUCT SPECIFICATIONS</h2>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "3") {
      return (
        <>
          <div className="container">
            <div className="row pb100 borderbottom item-center">
            {/* <div className="col-md-6">
               <img src={imgData.url} height="300" className="w-100 sliderparentimg" alt="" />
                <div className="flex_row">
                  <div className="row">
                    {filterData.map((data, i) =>
                      data.category === "shoerack" ? (
                        <div className="col-md-3 col-xs-4">
                          <div className="thumbnail" key={i}>
                            <img
                              className={data.id === imgData.id ? "clicked" : ""}
                              src={data.url}
                              onClick={() => setImages(data.id)}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 sliderparent">
                  <img src={imgData.url} className="w-100 sliderparentimg" alt="" />   
                    <div className="carosel" ref={ref}>
                      {filterData.map((data, i) =>
                        data.category === "shoerack" ? (
                              <img key={i} 
                                className={ (slide === i ? "slide" : "slide") + " " + (data.id === imgData.id ? "clicked" : "")}
                                src={data.url}
                                onClick={() => setImages(data.id)}
                              />
                        ) : (
                          ""
                        )
                      )}
                      </div>
                      <div className="arrow-btns">
                      <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
                      <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
                      </div>
                </div>
              <div className="col-md-6">
              <div className="product-material-mobile">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>
                <h2 className="grotesklight">SHOE RACK</h2>
                <div className="product-description">
                  <p className="grotesklight pbottom20 ptop20">
                    The Italia 3D peel is a top-of-the-line professional
                    perforated shovel, characterised by its unique
                    three-dimensional design, with elevations on the peel plate,
                    which reduce friction and increase the sliding of the pizza
                    on the plate.
                  </p>
                </div>
                <div className="product-material mb50">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>

                <Button
                  className="white quotebtn btn quotemobilebtn"
                  onClick={() => setModalShow(true)}
                >
                  GET YOUR QUOTE
                </Button>
                {/* <a href="https://api.whatsapp.com/send?phone=03331560504" target='_blank'><IoLogoWhatsapp className="whatsappicon"/></a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=03331560504"
                  target="_blank"
                  className="grotesklight black whatsappbtn"
                >
                  <IoLogoWhatsapp /> Whatsapp
                </a>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                 <div className="social ptop50">
                  <h6 className="grotesklight inline">Share on</h6>
                  <ul className="socialmedia sharebtns">
                    <li>
                      <a
                        href={`https://www.facebook.com/share.php?u=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.twitter.com/share?&url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pb100 borderbottom">
              <h2 className="grotesklight ptop100">PRODUCT SPECIFICATIONS</h2>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "4") {
      return (
        <>
          <div className="container">
            <div className="row pb100 borderbottom item-center">
            {/* <div className="col-md-6">
               <img src={imgData.url} height="300" className="w-100 sliderparentimg" alt="" />
                <div className="flex_row">
                  <div className="row">
                    {filterData.map((data, i) =>
                      data.category === "shoerack" ? (
                        <div className="col-md-3 col-xs-4">
                          <div className="thumbnail" key={i}>
                            <img
                              className={data.id === imgData.id ? "clicked" : ""}
                              src={data.url}
                              onClick={() => setImages(data.id)}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 sliderparent">
                  <img src={imgData.url} className="w-100 sliderparentimg" alt="" />   
                    <div className="carosel" ref={ref}>
                      {filterData.map((data, i) =>
                        data.category === "shoerack" ? (
                              <img key={i} 
                                className={ (slide === i ? "slide" : "slide") + " " + (data.id === imgData.id ? "clicked" : "")}
                                src={data.url}
                                onClick={() => setImages(data.id)}
                              />
                        ) : (
                          ""
                        )
                      )}     
                      </div>
                      <div className="arrow-btns">
                      <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
                      <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
                      </div>
                </div>
              <div className="col-md-6">
              <div className="product-material-mobile">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>
                <h2 className="grotesklight">SHOE RACK #2</h2>
                <div className="product-description">
                  <p className="grotesklight pbottom20 ptop20">
                    The Italia 3D peel is a top-of-the-line professional
                    perforated shovel, characterised by its unique
                    three-dimensional design, with elevations on the peel plate,
                    which reduce friction and increase the sliding of the pizza
                    on the plate.
                  </p>
                </div>
                <div className="product-material mb50">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "shoerack")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>

                <Button
                  className="white quotebtn btn quotemobilebtn"
                  onClick={() => setModalShow(true)}
                >
                  GET YOUR QUOTE
                </Button>
                {/* <a href="https://api.whatsapp.com/send?phone=03331560504" target='_blank'><IoLogoWhatsapp className="whatsappicon"/></a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=03331560504"
                  target="_blank"
                  className="grotesklight black whatsappbtn"
                >
                  <IoLogoWhatsapp /> Whatsapp
                </a>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                 <div className="social ptop50">
                  <h6 className="grotesklight inline">Share on</h6>
                  <ul className="socialmedia sharebtns">
                    <li>
                      <a
                        href={`https://www.facebook.com/share.php?u=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.twitter.com/share?&url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pb100 borderbottom">
              <h2 className="grotesklight ptop100">PRODUCT SPECIFICATIONS</h2>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "5") {
      return (
        <>
          <div className="container">
            <div className="row pb100 borderbottom item-center">
            {/* <div className="col-md-6">
               <img src={imgData.url} height="300" className="w-100 sliderparentimg" alt="" />
                <div className="flex_row">
                  <div className="row">
                    {filterData.map((data, i) =>
                      data.category === "shoerack3" ? (
                        <div className="col-md-3 col-xs-4">
                          <div className="thumbnail" key={i}>
                            <img
                              className={data.id === imgData.id ? "clicked" : ""}
                              src={data.url}
                              onClick={() => setImages(data.id)}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 sliderparent">
                  <img src={imgData.url} className="w-100 sliderparentimg" alt="" />   
                    <div className="carosel" ref={ref}>
                      {filterData.map((data, i) =>
                        data.category === "shoerack3" ? (
                              <img key={i} 
                                className={ (slide === i ? "slide" : "slide") + " " + (data.id === imgData.id ? "clicked" : "")}
                                src={data.url}
                                onClick={() => setImages(data.id)}
                              />
                        ) : (
                          ""
                        )
                      )}

                      </div>
                      <div className="arrow-btns">
                      <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
                      <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
                      </div>
                </div>
              <div className="col-md-6">
              <div className="product-material-mobile">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "shoerack3")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "shoerack3")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "shoerack3")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>
                <h2 className="grotesklight">SHOW RACK #3</h2>
                <div className="product-description">
                  <p className="grotesklight pbottom20 ptop20">
                    The Italia 3D peel is a top-of-the-line professional
                    perforated shovel, characterised by its unique
                    three-dimensional design, with elevations on the peel plate,
                    which reduce friction and increase the sliding of the pizza
                    on the plate.
                  </p>
                </div>
                <div className="product-material mb50">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "shoerack3")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "shoerack3")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "shoerack3")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>

                <Button
                  className="white quotebtn btn quotemobilebtn"
                  onClick={() => setModalShow(true)}
                >
                  GET YOUR QUOTE
                </Button>
                {/* <a href="https://api.whatsapp.com/send?phone=03331560504" target='_blank'><IoLogoWhatsapp className="whatsappicon"/></a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=03331560504"
                  target="_blank"
                  className="grotesklight black whatsappbtn"
                >
                  <IoLogoWhatsapp /> Whatsapp
                </a>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                <div className="social ptop50">
                  <h6 className="grotesklight inline">Share on</h6>
                  <ul className="socialmedia sharebtns">
                    <li>
                      <a
                        href={`https://www.facebook.com/share.php?u=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.twitter.com/share?&url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pb100 borderbottom">
              <h2 className="grotesklight ptop100">PRODUCT SPECIFICATIONS</h2>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "6") {
      return (
        <>
          <div className="container">
            <div className="row pb100 borderbottom item-center">
            {/* <div className="col-md-6">
               <img src={imgData.url} height="300" className="w-100 sliderparentimg" alt="" />
                <div className="flex_row">
                  <div className="row">
                    {filterData.map((data, i) =>
                      data.category === "wardrobe" ? (
                        <div className="col-md-3 col-xs-4">
                          <div className="thumbnail" key={i}>
                            <img
                              className={data.id === imgData.id ? "clicked" : ""}
                              src={data.url}
                              onClick={() => setImages(data.id)}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 sliderparent">
                  <img src={imgData.url} className="w-100 sliderparentimg" alt="" />   
                    <div className="carosel" ref={ref}>
                      {filterData.map((data, i) =>
                        data.category === "wardrobe" ? (
                              <img key={i} 
                                className={ (slide === i ? "slide" : "slide") + " " + (data.id === imgData.id ? "clicked" : "")}
                                src={data.url}
                                onClick={() => setImages(data.id)}
                              />
                        ) : (
                          ""
                        )
                      )}

                      </div>
                      <div className="arrow-btns">
                      <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide}/>
                      <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
                      </div>
                </div>
              <div className="col-md-6">
              <div className="product-material-mobile">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "wardrobe")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "wardrobe")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "wardrobe")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>
                <h2 className="grotesklight">WARDROBE</h2>
                <div className="product-description">
                  <p className="grotesklight pbottom20 ptop20">
                    The Italia 3D peel is a top-of-the-line professional
                    perforated shovel, characterised by its unique
                    three-dimensional design, with elevations on the peel plate,
                    which reduce friction and increase the sliding of the pizza
                    on the plate.
                  </p>
                </div>
                <div className="product-material mb50">
                  <h6 className="grotesklight orange mb-0">SELECT MATERIALS</h6>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="" onClick={() => setColor("white", "wardrobe")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("black", "wardrobe")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat2.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                    <Button variant="" onClick={() => setColor("silver", "wardrobe")}>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/mat1.svg"}
                        className="mat-btn"
                        alt="button"
                      />
                    </Button>
                  </ButtonGroup>
                </div>

                <Button
                  className="white quotebtn btn quotemobilebtn"
                  onClick={() => setModalShow(true)}
                >
                  GET YOUR QUOTE
                </Button>
                {/* <a href="https://api.whatsapp.com/send?phone=03331560504" target='_blank'><IoLogoWhatsapp className="whatsappicon"/></a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=03331560504"
                  target="_blank"
                  className="grotesklight black whatsappbtn"
                >
                  <IoLogoWhatsapp /> Whatsapp
                </a>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                <div className="social ptop50">
                  <h6 className="grotesklight inline">Share on</h6>
                  <ul className="socialmedia sharebtns">
                    <li>
                      <a
                        href={`https://www.facebook.com/share.php?u=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.twitter.com/share?&url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                        target="_blank"
                        className=""
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row pb100 borderbottom">
              <h2 className="grotesklight ptop100">PRODUCT SPECIFICATIONS</h2>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
              <div className="col-md-4 ptop50">
                <h5 className="grotesklight orange font-bold">Point #1</h5>
                <p className="grotesklight pbottom20 pt-10">
                  The Italia 3D peel is a top-of-the-line professional
                  perforated shovel, characterised by its unique
                  three-dimensional design, with elevations on the peel plate,
                  which reduce friction and increase the sliding of the pizza on
                  the plate.
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <section id="productdetail" className="mt-100">
          <Detail />
          <div className="container">
            <div className="row pb100 borderbottom">
              <h2 className="grotesklight ptop100">YOU MAY ALSO LIKE</h2>
              <OwlCarousel
                className="owl-theme"
                loop={true}
                margin={20}
                nav={true}
                navClass={["owl-prev", "owl-next"]}
                // navText={["<img src='.././assets/images/arrowowl.svg' className='arrowprev' /> PREV", "NEXT <img src='.././assets/images/arrowowl.svg' className='arrownext' />"]}
                navText={["PREV", "NEXT"]}
                dots={false}
                // items={3}
                autoplay={true}
                autoPlayTimeout={1000}
                autoPlayHoverPause={true}
                // slideTransition='linear'
                // autoPlaySpeed={6000}
                // smartSpeed={6000}
                responsive={state.responsive}
              >
                <div class="item">
                  <Link to={"/productdetail/" + 1}>
                    <div className="product-figure-parent">
                      <div class="product-figure">
                        <img
                          className="Sirv image-main w-100"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/doorimg1.png"
                          }
                          alt="product"
                        />
                      </div>
                      <div className="product-content">
                        <h2 className="grotesklight">OUT DOOR CANOPY</h2>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/images/dots.svg"
                          }
                          alt="revolve"
                          className="projectselection"
                        />
                        <p className="grotesklight grey">
                          Outdoor, Door Décor, Windows Interior
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div class="item">
                  <Link to={"/productdetail/" + 2}>
                    <div className="product-figure-parent">
                      <div class="product-figure">
                        <img
                          className="Sirv image-main w-100"
                          src={
                            process.env.PUBLIC_URL + "/assets/images/locker.png"
                          }
                          alt="product"
                        />
                      </div>
                      <div className="product-content">
                        <h2 className="grotesklight">LOCKER</h2>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/images/dots.svg"
                          }
                          alt="revolve"
                          className="projectselection"
                        />
                        <p className="grotesklight grey">
                          Indoor, Security, Home interior
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div class="item">
                  <Link to={"/productdetail/" + 3}>
                    <div className="product-figure-parent">
                      <div class="product-figure">
                        <img
                          className="Sirv image-main w-100"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/shoerac.png"
                          }
                          alt="product"
                        />
                      </div>
                      <div className="product-content">
                        <h2 className="grotesklight">SHOE RACK</h2>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/images/dots.svg"
                          }
                          alt="revolve"
                          className="projectselection"
                        />
                        <p className="grotesklight grey">
                          Indoor, Cleanliness , Home interior
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div class="item">
                  <Link to={"/productdetail/" + 5}>
                    <div className="product-figure-parent">
                      <div class="product-figure">
                        <img
                          className="Sirv image-main w-100"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/shoerack3.png"
                          }
                          alt="product"
                        />
                      </div>
                      <div className="product-content">
                        <h2 className="grotesklight">SHOE RACK #2</h2>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/images/dots.svg"
                          }
                          alt="revolve"
                          className="projectselection"
                        />
                        <p className="grotesklight grey">
                          Indoor, Cleanliness , Home interior
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div class="item">
                  <Link to={"/productdetail/" + 4}>
                    <div className="product-figure-parent">
                      <div class="product-figure">
                        <img
                          className="Sirv image-main w-100"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/shoerac.png"
                          }
                          alt="product"
                        />
                      </div>
                      <div className="product-content">
                        <h2 className="grotesklight">SHOE RACK #3</h2>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/images/dots.svg"
                          }
                          alt="revolve"
                          className="projectselection"
                        />
                        <p className="grotesklight grey">
                          Indoor, Cleanliness , Home interior
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
               
                <div class="item">
                  <Link to={"/productdetail/" + 6}>
                    <div className="product-figure-parent">
                      <div class="product-figure">
                        <img
                          className="Sirv image-main w-100"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/wardrobe6.png"
                          }
                          alt="product"
                        />
                      </div>
                      <div className="product-content">
                        <h2 className="grotesklight">WARDROBE</h2>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/images/dots.svg"
                          }
                          alt="revolve"
                          className="projectselection"
                        />
                        <p className="grotesklight grey">
                          Indoor, Bedroom Interior , Home interior
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
        <Phone />
        <Contact />
      </m.div>
    </>
  );
};

export default ProductDetail;

import React from "react";
import Button from "react-bootstrap/Button";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { HashLink as Link } from "react-router-hash-link";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  return (
    <>
      <section id="aboutsection">
        <div className="container">
          <div className="row landingrow item-center">
            <div className="col-md-6">
              <div className="">
                <div className="about-heading">
                  <h1 className="grotesklight">ABOUT US</h1>
                </div>
                <div className="aboutcontent-parent">
                  <p>
                    Aluminium Masters is more than just a company; it’s a vision
                    for a future where design and functionality coexist in
                    perfect harmony. Our journey began with a simple idea: to
                    transform ordinary spaces into extraordinary ones. We
                    believe that every home deserves the best, and we strive to
                    provide high-quality, modern aluminum products that elevate
                    your living experience.
                    <br />
                    <br />
                    Our commitment to innovation is at the heart of everything
                    we do. We are constantly pushing the boundaries of what’s
                    possible, exploring new designs and techniques to bring you
                    products that are not only beautiful but also practical.
                  </p>
                </div>
                <Link to="/about-us">
                  <Button type="" className="discovermorebtn">
                    LEARN MORE{" "}
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col-md-5 offset-md-1 item-end">
              <div className="reveal">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/homeabout.png"}
                  className="about-img"
                  alt="aboutus"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

import React, { useState,useRef } from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer , toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Phone = () => {
  const form = useRef();
  const [formState, setFormState] = useState({});
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_b9mblqb",
        "template_8jpctzb",
        form.current,
        "IyA-bzBzbkBDltsgB"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you! We've received your message. Tean will contact you shortly.",
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        },
        (error) => {
          toast.error("Incorrect Email", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
  };

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  
  return (
    <>
      <section id="phonesection">
        <div className="container">
          <div className="row landingrow pb100 item-center">
            <div className="col-md-5">
              <div className="landing-content">
                <div className="phone-heading">
                  <h1 className="grotesklight animateheading">
                    Ready For A Home
                  </h1>
                  <h1 className="grotesklight animateheading">
                    Transformation?
                  </h1>
                </div>
                <div className="phonecontent-parent grotesklight">
                  <p>
                    Let’s transform your home into an exquisite piece of modern
                    architecture. Make your living space truly luxurious!
                  </p>
                </div>
                <div className="form">
                  <form ref={form} onSubmit={sendEmail} className="emailform">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control phoneinput"
                        placeholder="Enter Your Phone Number"
                        name="phone"
                        // value={formState.phone || ""}
                        onChange={changeHandler}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className=""
                    >
                      GET A FREE QUOTE
                    </button>
                  </form>
                </div>
                <ToastContainer />
              </div>
            </div>
            <div className="col-md-6 offset-md-1 item-end">
              <div className="reveal">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/phone.png"}
                  className="about-img ctaimg"
                  alt="product"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Phone;

import React from "react";
import Button from "react-bootstrap/Button";
import { HashLink as Link } from "react-router-hash-link";

const Portfolio = () => {
  return (
    <>
      <section id="portfolio">
        <div className="container">
          <div className="row ">
            <div className="col-md-6">
              <div className="portfolio-heading">
                <h1 className="grotesklight animateheading">PORTFOLIO</h1>
                <p className="pbottom50 black">
                  Our portfolio is a testament to modern design and innovation.
                  Take a look at our completed projects.
                </p>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row pb100 borderbottom">
            <div className="col-md-6">
              <Link to={"/projects"}>
                <div className="col-md-12">
                  <div className="hover01 columnproject">
                    <div>
                      <figure>
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/Product IMG1.png"}
                          alt="revolve"
                          className="projectimgs"
                        />
                      </figure>
                      <span className="pspan1 grotesklight">Door Décor</span>
                    </div>
                  </div>
                  <div className="projectname">
                    <span className="pspan2 grotesklight">Door Canopy</span>
                  </div>
                </div>
              </Link>
              <Link to={"/projects"}>
              <div className="col-md-12 ptop50">
                <div className="hover01 columnproject">
                  <div>
                    <figure>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/Product IMG3.png"}
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                    <span className="pspan1 grotesklight">Main Door Décor</span>
                  </div>
                </div>
                <div className="projectname">
                  <span className="pspan2 grotesklight">Shoe rack</span>
                </div>
              </div>
              </Link>
            </div>
            <div className="col-md-6">
            <Link to={"/projects"}>
              <div className="col-md-12">
                <div className="hover01 columnproject">
                  <div>
                    <figure>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/Product IMG2.png"}
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                    <span className="pspan1 grotesklight">bedroom décor</span>
                  </div>
                </div>
                <div className="projectname">
                  <span className="pspan2 grotesklight">Wardrobe</span>
                </div>
              </div>
              </Link>
              <Link to={"/projects"}>
              <div className="col-md-12 ptop50">
                <div className="hover01 columnproject">
                  <div>
                    <figure>
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/Product IMG4.png"}
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                    <span className="pspan1 grotesklight">Home Décor</span>
                  </div>
                </div>
                <div className="projectname">
                  <span className="pspan2 grotesklight">Indoor Locker</span>
                </div>
              </div>
              </Link>
            </div>
            <Link to="/projects">
              <Button type="" className="discovermorebtn">
                Browse Projects{" "}
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;

import "./index.css";
import "./mediaQueries.css";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import MouseFollower from "mouse-follower";
import { AnimatePresence } from "framer-motion";
import jQuery from "jquery";
import AboutUs from "./components/aboutUs";
import Services from "./components/services";
import Products from "./components/products";
import AOS from "aos";
import { gsap } from "gsap";
import "aos/dist/aos.css";
import Projects from "./components/projects";
import Contactus from "./components/contactUs";
import ProductDetail from "./components/productDetail";
import ProjectDetail from "./components/projectDetail";
MouseFollower.registerGSAP(gsap);

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const spinner = document.getElementById("pre-loader");
 
  const cursor = new MouseFollower();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    AOS.init();
    const elements2 = document.querySelectorAll(".podcastimages");
    elements2.forEach((element) => {
      element.addEventListener("mouseenter", () => {
        cursor.setImg("./assets/images/mouse.png");
      });
      element.addEventListener("mouseleave", () => {
        cursor.removeImg();
      });
    });
  }, []);

  if (spinner) {
    jQuery(document).ready(function () {
      setTimeout(() => {
        jQuery(spinner).slideUp(1000);
      }, 4000);
      setLoading(true);
    });
  }

  // var cursor = document.querySelector('.cursor');

  return (
    <div className="App">
      <AnimatePresence exitBeforeEnter>
        {/* <Routes> */}
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/productdetail/:id" element={<ProductDetail />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projectdetail/:id" element={<ProjectDetail />} />
          <Route path="/contact-us" element={<Contactus />} />
          {/* </AnimatePresence> */}
        </Routes>
      </AnimatePresence>
    </div>
  );
}
export default App;

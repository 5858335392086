import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Clients = () => {
  const state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 2,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 5,
      },
    },
  };
  return (
    <>
      <section id="client">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="client-heading">
                <h1 className="grotesklight">OUR CLIENTS</h1>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row pb100">
            <OwlCarousel
              className="owl-theme"
              loop={true}
              margin={100}
              nav={false}
              dots={false}
              items={5}
              autoplay={true}
              rtl={true}
              //   autoPlayTimeout={1000}
              autoPlayHoverPause={true}
              slideTransition="linear"
              autoPlaySpeed={12000}
              smartSpeed={12000}
              responsive={state.responsive}
            >
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/cpr.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/rosevalley.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/green.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/lamontana.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/dha.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Bahria.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Gulberg.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
              <div class="item">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/TopCity.svg"}
                  alt="revolve"
                  className="projectimgs"
                />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;

import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Team = () => {
  // const state = {
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     450: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 1,
  //     },
  //     1000: {
  //       items: 1,
  //     },
  //   },
  // };
  return (
    <>
      <section id="teamsection">
        <div className="container">
          <h1 className="grotesklight">CEO MESSAGE</h1>
          <div className="row mb-100">
            <div className="col-md-6">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/mathias.png"}
                className="item1img"
                alt="team"
              />
            </div>
            <div className="col-md-6 team-content">
              <h4 className="grotesklight white">
                “As the CEO of Aluminium Masters, I feel honored to lead a
                diverse team dedicated to redefining excellence in the aluminium
                industry. Our undeterred commitment to craftsmanship, client
                satisfaction, and cutting-edge technology sets us apart.
                <br/><br/>At Aluminium Masters, we forge great partnerships and create unique solutions that stand the test of time. Explore our world of sustainable aluminium solutions, where every project is an opportunity to elevate standards and exceed expectations.<br/><br/>Thank you for choosing Aluminium Masters as your trusted partner on the path to architectural distinction!”
              </h4>
              <div className="team-title">
                <h3 className="grotesklight white mb-0">Mattias Svensson</h3>
              </div>
            </div>
          </div>
      
        </div>
        <div className="pattern">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/Brand Pattern.svg"}
            className="pattern-img"
            alt="team"
          />
        </div>
      </section>
    </>
  );
};

export default Team;

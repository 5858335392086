import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import Navcomponent from "./navbar";
import { motion as m } from "framer-motion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OwlCarousel from "react-owl-carousel";
import Phone from "./phone";
import Contact from "./contact";
import { HashLink as Link } from "react-router-hash-link";
import { gsap, Power2 } from "gsap";
import SplitType from "split-type";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const ProjectDetail = () => {
  const [ids, setIds] = useState();
  const params = useParams();
  const id = params.id;
  const form = useRef();
  const [formState, setFormState] = useState({});
  const state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  // useEffect(() => {
  //   console.log("pathcheck", id);
  //   setIds(id);
  // });
  useEffect(() => {
    console.log("pathcheck", id);
    setIds(id);
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });

      // let tl1 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: revealtext,
      //     toggleActions: "restart none none reset",
      //   },
      // });

      // tl1.to(headingreveal, {
      //   y: 0,
      //   stagger: 0.05,
      //   delay: 0.3,
      //   duration: 0.1,
      // });
    });
    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4ikopok",
        "template_ijgmu1o",
        form.current,
        "IyA-bzBzbkBDltsgB"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you! We've received your message. Tean will contact you shortly.",
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        },
        (error) => {
          toast.error("Incorrect Email", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
  };

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  const MyVerticallyCenteredModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            LEAVE US A NOTE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={form} onSubmit={sendEmail} className="emailform">
            {/* <form ref={form} className="emailform"> */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contactform"
                    id="exampleInputFname"
                    placeholder="First Name *"
                    name="fname"
                    onChange={changeHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control contactform"
                    id="exampleInputFname"
                    placeholder="Last Name *"
                    name="lname"
                    onChange={changeHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control contactform"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email Address *"
                    name="email"
                    onChange={changeHandler}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="phone"
                    className="form-control contactform"
                    id="exampleInputPhone"
                    aria-describedby=""
                    placeholder="Phone Number *"
                    name="phone"
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="form-group">
                  <textarea
                    className="form-control contactform"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Your Message"
                    name="message"
                    onChange={changeHandler}
                    required
                  ></textarea>
                </div>
              </div>
            </div>

            <button type="submit" className="submitemail">
              Submit
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} className="quotebtn">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const Detail = () => {
    const [modalShow, setModalShow] = React.useState(false);
    if (ids === "1") {
      return (
        <>
          <section id="projectdetail" className="mb-100">
            <div className="container pb100 h-80">
              <div className="row landingrow">
                <div className="col-md-10">
                  <div className="">
                    <div className="projects-heading">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                      <div className="service-maincontent">
                        <p>Project Description</p>
                      </div>
                    </div>
                    <div className="projects-headingmobile">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 item-end"></div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row item-center">
              <div className="col-md-6">
                <h2 className="grotesklight">ABOUT THE PROJECT</h2>
                <p className="grotesklight pbottom20 ptop20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Tortor consequat id porta nibh venenatis. Justo laoreet sit
                  amet cursus sit amet. Phasellus vestibulum lorem sed risus
                  ultricies tristique nulla aliquet. Mus mauris vitae ultricies
                  leo integer. Libero justo laoreet sit amet cursus sit amet.
                  Etiam tempor orci eu lobortis. Massa enim nec dui nunc mattis
                  enim. Tempor orci eu lobortis elementum nibh. Tincidunt eget
                  nullam non nisi. Blandit turpis cursus in hac habitasse platea
                  dictumst quisque. Aliquam id diam maecenas ultricies mi eget
                  mauris.
                </p>
              </div>
              <div className="col-md-4 offset-md-2">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Client</h5>
                    <p className="grotesklight grey">Constantine Pr</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Industry</h5>
                    <p className="grotesklight grey">Marketing Agency</p>
                  </div>
                </div>
                <div className="row ptop20">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Services</h5>
                    <p className="grotesklight grey">Windows Installation</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Year</h5>
                    <p className="grotesklight grey">2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row borderbottom pb100">
              <div className="col-md-12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail1.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail2.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail3.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail4.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail5.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "2") {
      return (
        <>
          <section id="projectdetail" className="mb-100">
            <div className="container pb100 h-80">
              <div className="row landingrow">
                <div className="col-md-10">
                  <div className="">
                    <div className="projects-heading">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                      <div className="service-maincontent">
                        <p>Project Description</p>
                      </div>
                    </div>
                    <div className="projects-headingmobile">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 item-end"></div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row item-center">
              <div className="col-md-6">
                <h2 className="grotesklight">ABOUT THE PROJECT</h2>
                <p className="grotesklight pbottom20 ptop20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Tortor consequat id porta nibh venenatis. Justo laoreet sit
                  amet cursus sit amet. Phasellus vestibulum lorem sed risus
                  ultricies tristique nulla aliquet. Mus mauris vitae ultricies
                  leo integer. Libero justo laoreet sit amet cursus sit amet.
                  Etiam tempor orci eu lobortis. Massa enim nec dui nunc mattis
                  enim. Tempor orci eu lobortis elementum nibh. Tincidunt eget
                  nullam non nisi. Blandit turpis cursus in hac habitasse platea
                  dictumst quisque. Aliquam id diam maecenas ultricies mi eget
                  mauris.
                </p>
              </div>
              <div className="col-md-4 offset-md-2">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Client</h5>
                    <p className="grotesklight grey">Constantine Pr</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Industry</h5>
                    <p className="grotesklight grey">Marketing Agency</p>
                  </div>
                </div>
                <div className="row ptop20">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Services</h5>
                    <p className="grotesklight grey">Windows Installation</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Year</h5>
                    <p className="grotesklight grey">2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row borderbottom pb100">
              <div className="col-md-12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail1.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail2.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail3.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail4.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail5.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "3") {
      return (
        <>
          <section id="projectdetail" className="mb-100">
            <div className="container pb100 h-80">
              <div className="row landingrow">
                <div className="col-md-10">
                  <div className="">
                    <div className="projects-heading">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                      <div className="service-maincontent">
                        <p>Project Description</p>
                      </div>
                    </div>
                    <div className="projects-headingmobile">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 item-end"></div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row item-center">
              <div className="col-md-6">
                <h2 className="grotesklight">ABOUT THE PROJECT</h2>
                <p className="grotesklight pbottom20 ptop20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Tortor consequat id porta nibh venenatis. Justo laoreet sit
                  amet cursus sit amet. Phasellus vestibulum lorem sed risus
                  ultricies tristique nulla aliquet. Mus mauris vitae ultricies
                  leo integer. Libero justo laoreet sit amet cursus sit amet.
                  Etiam tempor orci eu lobortis. Massa enim nec dui nunc mattis
                  enim. Tempor orci eu lobortis elementum nibh. Tincidunt eget
                  nullam non nisi. Blandit turpis cursus in hac habitasse platea
                  dictumst quisque. Aliquam id diam maecenas ultricies mi eget
                  mauris.
                </p>
              </div>
              <div className="col-md-4 offset-md-2">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Client</h5>
                    <p className="grotesklight grey">Constantine Pr</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Industry</h5>
                    <p className="grotesklight grey">Marketing Agency</p>
                  </div>
                </div>
                <div className="row ptop20">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Services</h5>
                    <p className="grotesklight grey">Windows Installation</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Year</h5>
                    <p className="grotesklight grey">2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row borderbottom pb100">
              <div className="col-md-12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail1.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail2.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail3.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail4.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail5.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "4") {
      return (
        <>
          <section id="projectdetail" className="mb-100">
            <div className="container pb100 h-80">
              <div className="row landingrow">
                <div className="col-md-10">
                  <div className="">
                    <div className="projects-heading">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                      <div className="service-maincontent">
                        <p>Project Description</p>
                      </div>
                    </div>
                    <div className="projects-headingmobile">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 item-end"></div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row item-center">
              <div className="col-md-6">
                <h2 className="grotesklight">ABOUT THE PROJECT</h2>
                <p className="grotesklight pbottom20 ptop20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Tortor consequat id porta nibh venenatis. Justo laoreet sit
                  amet cursus sit amet. Phasellus vestibulum lorem sed risus
                  ultricies tristique nulla aliquet. Mus mauris vitae ultricies
                  leo integer. Libero justo laoreet sit amet cursus sit amet.
                  Etiam tempor orci eu lobortis. Massa enim nec dui nunc mattis
                  enim. Tempor orci eu lobortis elementum nibh. Tincidunt eget
                  nullam non nisi. Blandit turpis cursus in hac habitasse platea
                  dictumst quisque. Aliquam id diam maecenas ultricies mi eget
                  mauris.
                </p>
              </div>
              <div className="col-md-4 offset-md-2">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Client</h5>
                    <p className="grotesklight grey">Constantine Pr</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Industry</h5>
                    <p className="grotesklight grey">Marketing Agency</p>
                  </div>
                </div>
                <div className="row ptop20">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Services</h5>
                    <p className="grotesklight grey">Windows Installation</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Year</h5>
                    <p className="grotesklight grey">2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row borderbottom pb100">
              <div className="col-md-12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail1.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail2.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail3.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail4.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail5.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "5") {
      return (
        <>
          <section id="projectdetail" className="mb-100">
            <div className="container pb100 h-80">
              <div className="row landingrow">
                <div className="col-md-10">
                  <div className="">
                    <div className="projects-heading">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                      <div className="service-maincontent">
                        <p>Project Description</p>
                      </div>
                    </div>
                    <div className="projects-headingmobile">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 item-end"></div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row item-center">
              <div className="col-md-6">
                <h2 className="grotesklight">ABOUT THE PROJECT</h2>
                <p className="grotesklight pbottom20 ptop20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Tortor consequat id porta nibh venenatis. Justo laoreet sit
                  amet cursus sit amet. Phasellus vestibulum lorem sed risus
                  ultricies tristique nulla aliquet. Mus mauris vitae ultricies
                  leo integer. Libero justo laoreet sit amet cursus sit amet.
                  Etiam tempor orci eu lobortis. Massa enim nec dui nunc mattis
                  enim. Tempor orci eu lobortis elementum nibh. Tincidunt eget
                  nullam non nisi. Blandit turpis cursus in hac habitasse platea
                  dictumst quisque. Aliquam id diam maecenas ultricies mi eget
                  mauris.
                </p>
              </div>
              <div className="col-md-4 offset-md-2">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Client</h5>
                    <p className="grotesklight grey">Constantine Pr</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Industry</h5>
                    <p className="grotesklight grey">Marketing Agency</p>
                  </div>
                </div>
                <div className="row ptop20">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Services</h5>
                    <p className="grotesklight grey">Windows Installation</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Year</h5>
                    <p className="grotesklight grey">2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row borderbottom pb100">
              <div className="col-md-12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail1.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail2.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail3.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail4.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail5.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "6") {
      return (
        <>
          <section id="projectdetail" className="mb-100">
            <div className="container pb100 h-80">
              <div className="row landingrow">
                <div className="col-md-10">
                  <div className="">
                    <div className="projects-heading">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                      <div className="service-maincontent">
                        <p>Project Description</p>
                      </div>
                    </div>
                    <div className="projects-headingmobile">
                      <h1 className="grotesklight animateheading">
                        PROJECT NAME!
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 item-end"></div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row item-center">
              <div className="col-md-6">
                <h2 className="grotesklight">ABOUT THE PROJECT</h2>
                <p className="grotesklight pbottom20 ptop20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Tortor consequat id porta nibh venenatis. Justo laoreet sit
                  amet cursus sit amet. Phasellus vestibulum lorem sed risus
                  ultricies tristique nulla aliquet. Mus mauris vitae ultricies
                  leo integer. Libero justo laoreet sit amet cursus sit amet.
                  Etiam tempor orci eu lobortis. Massa enim nec dui nunc mattis
                  enim. Tempor orci eu lobortis elementum nibh. Tincidunt eget
                  nullam non nisi. Blandit turpis cursus in hac habitasse platea
                  dictumst quisque. Aliquam id diam maecenas ultricies mi eget
                  mauris.
                </p>
              </div>
              <div className="col-md-4 offset-md-2">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Client</h5>
                    <p className="grotesklight grey">Constantine Pr</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Industry</h5>
                    <p className="grotesklight grey">Marketing Agency</p>
                  </div>
                </div>
                <div className="row ptop20">
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Services</h5>
                    <p className="grotesklight grey">Windows Installation</p>
                  </div>
                  <div className="col-md-6">
                    <h5 className="grotesklight mb-0">Year</h5>
                    <p className="grotesklight grey">2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row borderbottom pb100">
              <div className="col-md-12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail1.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail2.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail3.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail4.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
              <div className="col-md-12 mt-20">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/projectdetail5.png"
                  }
                  alt="project"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <Detail />
        <div className="container proectcarousel">
          <div className="row pb100 borderbottom mt-100">
            {/* <h2 className="grotesklight ptop100">YOU MAY ALSO LIKE</h2> */}
            <OwlCarousel
              className="owl-theme"
              loop={true}
              margin={20}
              nav={true}
              navClass={["owl-prev", "owl-next"]}
              // navText={["<img src='.././assets/images/arrowowl.svg' className='arrowprev' /> PREV", "NEXT <img src='.././assets/images/arrowowl.svg' className='arrownext' />"]}
              navText={["PREV", "NEXT"]}
              dots={false}
              // items={3}
              autoplay={false}
              autoPlayTimeout={1000}
              autoPlayHoverPause={true}
              // slideTransition='linear'
              // autoPlaySpeed={6000}
              // smartSpeed={6000}
              responsive={state.responsive}
            >
              <div class="item">
                <Link to={"/projectdetail/" + 1}>
                  <div className="hover01 columnproject">
                    <figure>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/allIMG1.png"
                        }
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                  </div>
                  <h3 className="grotesklight black">PROJECT #1</h3>
                </Link>
              </div>
              <div class="item">
                <Link to={"/projectdetail/" + 2}>
                  <div className="hover01 columnproject">
                    <figure>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/allIMG2.png"
                        }
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                  </div>
                  <h3 className="grotesklight black">PROJECT #2</h3>
                </Link>
              </div>
              <div class="item">
                <Link to={"/projectdetail/" + 3}>
                  <div className="hover01 columnproject">
                    <figure>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/allIMG1.png"
                        }
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                  </div>
                  <h3 className="grotesklight black">PROJECT #3</h3>
                </Link>
              </div>
              <div class="item">
                <Link to={"/projectdetail/" + 4}>
                  <div className="hover01 columnproject">
                    <figure>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/allIMG4.png"
                        }
                        alt="revolve"
                        className="projectimgs"
                      />
                    </figure>
                  </div>
                  <h3 className="grotesklight black">PROJECT #4</h3>
                </Link>
              </div>
            </OwlCarousel>
          </div>
        </div>
        <Phone />
        <Contact />
      </m.div>
    </>
  );
};

export default ProjectDetail;

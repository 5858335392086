import React, { useEffect } from "react";
import Navcomponent from "./navbar";
import Phone from "./phone";
import Contact from "./contact";
import SplitType from "split-type";
import { motion as m } from "framer-motion";
import { gsap, Power2 } from "gsap";

const Services = () => {
  useEffect(() => {
    let revealContainers = document.querySelectorAll(".reveal");
    const ourText = new SplitType(".animateheading", { types: "chars" });
    const chars = ourText.chars;

    revealContainers.forEach((container) => {
      let image = container.querySelector("img");
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          toggleActions: "restart none restart reset",
        },
      });
      tl.set(container, { autoAlpha: 1 });
      tl.from(container, 1.5, {
        xPercent: 100,
        ease: Power2.out,
      });
      tl.from(image, 1.5, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        ease: Power2.out,
      });

      // let tl1 = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: revealtext,
      //     toggleActions: "restart none none reset",
      //   },
      // });

      // tl1.to(headingreveal, {
      //   y: 0,
      //   stagger: 0.05,
      //   delay: 0.3,
      //   duration: 0.1,
      // });
    });
    gsap.fromTo(
      chars,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);
  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <Navcomponent />
        <section id="services">
          <div className="container">
            <div className="row landingrow">
              <div className="col-md-12">
                <div className="service-content-main">
                  <div className="services-heading">
                    <h1 className="grotesklight animateheading">
                      CRAFTING BRILLIANCE IN ALUMINIUM:
                    </h1>
                    <h1 className="grotesklight animateheading">
                      YOUR VISION, OUR EXPERTISE!
                    </h1>
                  </div>
                  <div className="services-mobile">
                    <h1 className="grotesklight animateheading">CRAFTING</h1>
                    <h1 className="grotesklight animateheading">
                      BRILLIANCE IN
                    </h1>
                    <h1 className="grotesklight animateheading">ALUMINIUM:</h1>
                    <h1 className="grotesklight animateheading">
                      YOUR VISION,
                    </h1>
                    <h1 className="grotesklight animateheading">
                      OUR EXPERTISE!
                    </h1>
                  </div>
                  <div className="service-maincontent">
                    <p>
                      At Aluminum Masters, we craft more than just products.
                      From precision engineering<br/> to bespoke designs, we are your
                      partners in unlocking the potential of aluminum.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 item-end"></div>
            </div>
          </div>
          
        </section>
        <section className="homesection8">
          <div className="container">
            <div className="row pbottom50">
              <div className="col-md-6">
                <div className="servicebottom-content">
                  <div className="servicebottom-heading">
                    <h1 className="grotesklight">OUR SERVICES</h1>
                  </div>
                  <p>
                    At Aluminum Masters, we take pride in being your premier
                    destination for top-notch aluminum solutions.
                  </p>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="row ">
              <div className="col-md-6">
                <div className="cardsimg">
                  <div className="service-heading white">
                    <h2 className="grotesklight">DOORS INSTALLATION</h2>
                  </div>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/S1.png"}
                    alt="icons"
                    className="cardsimgpic"
                  />
                  <div className="overlay-cardsimg">
                    <h2 className="grotesklight">DOORS INSTALLATION</h2>
                    <div className="cards-para">
                      <p className="grotesklight">
                        Step into a world of durability and elegance with our
                        custom-crafted aluminum doors. From sleek modern designs
                        to timeless classics, we create entrances that make a
                        lasting impression.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="cardsimg">
                  <div className="service-heading white">
                    <h2 className="grotesklight">WINDOWS INSTALLATION</h2>
                  </div>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/S2.png"}
                    alt="icons"
                    className="cardsimgpic"
                  />
                  <div className="overlay-cardsimg">
                    <h2 className="grotesklight">WINDOWS INSTALLATION</h2>
                    <div className="cards-para">
                      <p className="grotesklight">
                        Step into a world of durability and elegance with our
                        custom-crafted aluminum doors. From sleek modern designs
                        to timeless classics, we create entrances that make a
                        lasting impression.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ptop20 pb100 borderbottom">
              <div className="col-md-6">
                <div className="cardsimg">
                  <div className="service-heading white">
                    <h2 className="grotesklight">KITCHEN CABINETS</h2>
                    <h2 className="grotesklight">INSTALLATION</h2>
                  </div>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/S3.png"}
                    alt="icons"
                    className="cardsimgpic"
                  />
                  <div className="overlay-cardsimg">
                    <h2 className="grotesklight">KITCHEN CABINETS</h2>
                    <h2 className="grotesklight">INSTALLATION</h2>
                    <div className="cards-para">
                      <p className="grotesklight">
                        Step into a world of durability and elegance with our
                        custom-crafted aluminum doors. From sleek modern designs
                        to timeless classics, we create entrances that make a
                        lasting impression.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="cardsimg">
                  <div className="service-heading white">
                    <h2 className="grotesklight">INDOOR ACCESSORIES</h2>
                    <h2 className="grotesklight">INSTALLATION</h2>
                  </div>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/S4.png"}
                    alt="icons"
                    className="cardsimgpic"
                  />
                  <div className="overlay-cardsimg">
                    <h2 className="grotesklight">INDOOR ACCESSORIES</h2>
                    <h2 className="grotesklight">INSTALLATION</h2>
                    <div className="cards-para">
                      <p className="grotesklight">
                        Step into a world of durability and elegance with our
                        custom-crafted aluminum doors. From sleek modern designs
                        to timeless classics, we create entrances that make a
                        lasting impression.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row pbottom50">
              <div className="col-md-6">
                <div className="">
                  <div className="doit-heading">
                    <h1 className="grotesklight">HOW WE DO IT?</h1>
                    <p>
                    Seamless Excellence from Quotation to Installation
                  </p>
                  </div>
                  
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
          <div className="container relative borderbottom pb100">
            <div className="zigzag">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/zigzag.svg"}
                className="footerlogo"
                alt="logo"
              />
            </div>
            <div className="services-parent services-parent1">
              <div className="service-img">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/service1.png"}
                  className="footerlogo"
                  alt="logo"
                />
              </div>
              <div className="service-content">
                <h2 className="grotesklight">Client Inquiry</h2>
                <p className="grotesklight">
                  Initiate the journey towards unparalleled aluminum solutions
                  by reaching out to Aluminum Masters. Our dedicated team is
                  ready to listen, understand your requirements, and guide you
                  through the process.
                </p>
              </div>
            </div>
            <div className="services-parent services-parent2">
              <div className="service-img">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/service2.png"}
                  className="footerlogo"
                  alt="logo"
                />
              </div>
              <div className="service-content">
                <h2 className="grotesklight">On-Site Assessment</h2>
                <p className="grotesklight">
                  Upon receiving your inquiry, our skilled professionals
                  schedule a visit to your location. This on-site assessment
                  allows us to gather essential details, assess the space, and
                  understand your vision for the project.
                </p>
              </div>
            </div>
            <div className="services-parent services-parent3">
              <div className="service-img">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/service3.png"}
                  className="footerlogo"
                  alt="logo"
                />
              </div>
              <div className="service-content">
                <h2 className="grotesklight">Customized Quote</h2>
                <p className="grotesklight">
                  Armed with the insights from the on-site assessment, we
                  meticulously craft a customized quote tailored to your
                  specific needs and preferences. Transparency and accuracy are
                  the cornerstones of our quoting process.
                </p>
              </div>
            </div>
            <div className="services-parent services-parent4">
              <div className="service-img">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/service4.png"}
                  className="footerlogo"
                  alt="logo"
                />
              </div>
              <div className="service-content">
                <h2 className="grotesklight">Client Approval</h2>
                <p className="grotesklight">
                  Review the comprehensive quote and take the next step towards
                  transforming your space. Once you've approved the proposal, we
                  move swiftly to the implementation phase, ensuring a seamless
                  transition from planning to execution.
                </p>
              </div>
            </div>
            <div className="services-parent services-parent5">
              <div className="service-img">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/service5.png"}
                  className="footerlogo"
                  alt="logo"
                />
              </div>
              <div className="service-content">
                <h2 className="grotesklight">Precision Installation</h2>
                <p className="grotesklight">
                  Our seasoned installation team takes charge, bringing your
                  vision to life with precision and expertise. We prioritize
                  efficiency without compromising on the meticulous attention to
                  detail that defines Aluminum Masters' installations.
                </p>
              </div>
            </div>
            <div className="services-parent services-parent6">
              <div className="service-img">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/service6.png"}
                  className="footerlogo"
                  alt="logo"
                />
              </div>
              <div className="service-content">
                <h2 className="grotesklight">Project Completion</h2>
                <p className="grotesklight">
                  Witness the culmination of our collective efforts as your
                  project reaches completion. Our commitment to excellence
                  ensures that every detail aligns with your expectations,
                  leaving you with an aluminum solution that exceeds
                  your aspirations.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Phone />
        <Contact />
      </m.div>
    </>
  );
};

export default Services;

import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FaInstagramSquare, FaTiktok } from "react-icons/fa";
import { AiFillLinkedin, AiFillFacebook } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section id="contact" className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="footerlogo">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/Brand Logo.png"}
                  className="footerlogo"
                  alt="logo"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="gridview gridview1">
                <h4 className="groteskregular">QUICK LINKS</h4>
                <div className="footerlinks mar20">
                  <ul className="rounded">
                    <li>
                      <Link className="" to="/">
                        HOME
                      </Link>
                    </li>
                    <li>
                      <Link className="" to="/about-us">
                        ABOUT US
                      </Link>
                    </li>
                    <li>
                      <Link className="" to="/products">
                        PRODUCTS
                      </Link>
                    </li>
                    <li>
                      <Link className="" to="/services">
                        SERVICES
                      </Link>
                    </li>
                    <li>
                      <Link className="" to="/">
                        CONTACT US
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="gridview gridview1 gridview2">
                <h4 className="groteskregular">SOCIAL LINKS</h4>
                <div className="footerlinks mar20">
                  {/* <ul className="rounded">
                    <li>
                      <a href="">KITCHEN INTERIOR</a>
                    </li>
                    <li>
                      <a href="">HOME INTERIOR</a>
                    </li>
                    <li>
                      <a href="">OFFICE INTERIOR</a>
                    </li>
                    <li>
                      <a href="">WARDROBE INTERIOR</a>
                    </li>
                  </ul> */}
                  <ul className="rounded socialmedia">
                    <li>
                     <AiFillFacebook />
                      <a
                        href="https://www.facebook.com/aluminiummasterspk?mibextid=LQQJ4d"
                        target="_blank"
                        className=""
                      >
                        FACEBOOK
                      </a>
                    </li>
                    <li>
                    <FaInstagramSquare />
                      <a
                        href="https://instagram.com/aluminiummasters?igshid=MzMyNGUyNmU2YQ=="
                        target="_blank"
                        className=""
                      >
                        INSTAGRAM
                      </a>
                    </li>
                    <li>
                    <AiFillLinkedin />
                    <a
                        href="https://instagram.com/aluminiummasters?igshid=MzMyNGUyNmU2YQ=="
                        target="_blank"
                        className=""
                      >
                        LINKEDIN
                      </a>
                    </li>
                    <li>
                    <FaTiktok />
                    <a
                        href="https://instagram.com/aluminiummasters?igshid=MzMyNGUyNmU2YQ=="
                        target="_blank"
                        className=""
                      >
                        TIKTOK
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row lastrow">
            <div className="col-md-6 footer-leftcontent pl-0">
              <p>
                @ COPYRIGHT <span className="orange"><NavLink smooth={true}
                      to="/">ALUMINIUM MASTERS</NavLink></span>
              </p>
            </div>
            <div className="col-md-6 footer-rightcontent pr-0">
              <ul className="">
                <li>
                 <p>ALL RIGHTS RESERVED @ COPYRIGHT 2023</p>
                </li>
                {/* <li>
                  <a
                    href="https://instagram.com/aluminiummasters?igshid=MzMyNGUyNmU2YQ=="
                    target="_blank"
                    className=""
                  >
                    INSTAGRAM
                  </a>
                </li> */}
                {/* <li>
                  <a href="" target="_blank" className="">
                    TWITTER
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="pattern2">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/Pattern2.svg"}
            className="footerlogo"
            alt="logo"
          />
        </div>
      </section>
    </>
  );
};

export default Contact;
